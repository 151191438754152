import React from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../_actions';
import { userActions } from '../../_actions';
import BeatLoader from "react-spinners/BeatLoader";
import {Card,Container,Col,Row} from 'react-bootstrap';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: ''
        };

        this.props.logout();     
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = (e)=> {
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password } = this.state;      
        
        if (username && password) {
            this.props.login(username, password);
        }
    }
    

    render() {
	console.log("RCC:: Login Page");    
        const { loggingIn } = this.props;
        const { username, password, submitted } = this.state;
        
        return (
<div>
            <Container>
                <Row  className="justify-content-center mt-6 mb-5">
                    <Col lg={6} sm={12} md={9} xs={12}>
                    <Card className="sombra-1">
                    <Card.Header className="p-2 text-center bg-primario">
                         <h2 className="text-white">Acceso al Sistema</h2>
                         <span className="text-white small"></span>
                    </Card.Header>
                    <Card.Body className="mt-4 th_secundario">                                            
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                <label htmlFor="username">Usuario</label>  
                                {submitted && !username &&
                                    <div className="help-block text-danger"><i className="fas fa-exclamation-circle"></i> Usuario Inválido</div>
                                }
                                <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fas fa-user text-secundario"></i></div>
                                </div>
                                <input type="text" className="form-control" name="username" value={username} onChange={this.handleChange} />
                                
                                </div>
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password">Contraseña</label>
                                {submitted && !password &&
                                    <div className="help-block"><i className="fas fa-exclamation-circle"></i> Contraseña Inválida</div>
                                }
                                <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fas fa-lock"></i> </div>
                                </div>
                                <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                
                                </div>
                            </div>

                            <Row className="justify-content-center mb-2 mt-5">
                                <button className="btns btns-secundario">Ingresar
                                {loggingIn &&
                                    <div className="sweet-loading">
                                    <BeatLoader  
                                      size={10}
                                      color={"#FFFFFF"}
                                      loading={this.state.loading}
                                    />
                                    </div>
                                }
                                </button>
                            </Row>                   
                        </form>
                    </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </Container>  
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    enviarError: alertActions.error,
    enviarSuccess:alertActions.success
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
