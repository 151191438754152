import React, { useEffect, useState } from 'react';
import { Field, getIn } from 'formik';
import Moment from 'react-moment';
import { Col, Row, Container, Button, Table, OverlayTrigger, Popover, Modal } from 'react-bootstrap';
import { TIPO_ESPECIALISTA } from '../_helpers';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import BeatLoader from "react-spinners/BeatLoader";
import { ordenDeTrabajoService } from '../_services';
import { candadoService } from '../_services';

export const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="help-block text-danger">
          <i className="fas fa-exclamation-circle"
          ></i> {typeof error === 'string' ? error : ''}</div>
      ) : null;
    }}</Field>
);

export const MyToolTip = ({ texto, id }) => (
  <ReactTooltip place="bottom" id={id} aria-haspopup='false' >
    <span>{texto}</span>
  </ReactTooltip>
);

export const IconLoto = ({ tipo, size }) => (
  <>

  </>
);

export const ToolTipAyuda = ({ texto }) => (
  <OverlayTrigger
    placement="top"
    trigger={['hover', 'focus']}
    overlay={(
      <Popover>
        <Popover.Content>
          {texto}
        </Popover.Content>
      </Popover>
    )}>
    <div className="btns btn-link text-uva">
      <span className="material-icons help-user">help</span>
    </div>
  </OverlayTrigger>
);

export const CandadoDP = ({ rowData, listadoDeOrdenesDeTrabajo }) => {

  const [showModal, setShowModal] = useState(false);

  const ordenDeTrabajo = listadoDeOrdenesDeTrabajo?.filter(OT => OT.candados.includes(rowData.rowData.id))[0];
  //const imagenCandado = ordenDeTrabajo?.candadosOrdenDeTrabajo?.filter(candado => candado.candadoId === rowData.rowData.id)?.[0]?.imagenPokaYoke;
  const [ imagenCandado, setImagenCandado ] = useState(null);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  rowData.rowData.logs = rowData.rowData.logs?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

  useEffect(() => {
    const cargarImagenCandado = async (id) => {
      try {
        const candadoPdT = await ordenDeTrabajoService.traerImagenCandado(id);
        setImagenCandado(candadoPdT.imagenPokaYoke);
      } catch (error) {
        console.error(error);
      }
    };

    if (ordenDeTrabajo) {
      cargarImagenCandado(ordenDeTrabajo?.candadosOrdenDeTrabajo?.filter(candado => candado.candadoId === rowData.rowData.id)?.[0]?.id);
    }
  }, []);

  return <Container fluid className="candado-container">
    <Modal show={showModal} onHide={handleClose} style={{ minWidth: '100vw', maxWidth: '80vw', width: 'auto' }}>
      <Modal.Header closeButton>
        <Modal.Title>Imagen del candado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {imagenCandado && <img src={`data:image/png;base64,${imagenCandado}`} alt="Imagen del candado" className="img-fluid" />}
      </Modal.Body>
    </Modal>
    <Row className="bg-silver p-3 flex-column flex-md-row">
      <Col xs={12} md={6} className="mb-3 mb-md-0">
        <h5 className="text-md-left text-center"><span className="badge badge-secondary font-weight-light">Bloqueos Activos</span></h5>
        <div className="table-responsive">
          <Table className="sombra-1" hover>
            <thead className="bg-white">
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
              </tr>
            </thead>
            <tbody>
              {rowData.rowData?.bloqueos && rowData.rowData.bloqueos.map((bloqueo, index) =>
                <tr key={index}>
                  <td>
                    <Moment date={bloqueo.createdDate} format="DD/MM/YYYY HH:mm" />
                  </td>
                  <td>
                    {bloqueo.rol}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {imagenCandado ? <img
            src={`data:image/png;base64,${imagenCandado}`}
            className="img-fluid img-size"
            style={{ marginRight: '10px', cursor: 'pointer', maxWidth: '500px' }}
            onClick={() => handleShow(imagenCandado)}
          /> : "No se encontro imagen"}
        </div>
      </Col>

      <Col xs={12} md={6}>
        <h5 className="text-md-left text-center"><span className="badge badge-secondary font-weight-light">Historico</span></h5>
        <div className="table-responsive">
          <Table className="sombra-1" hover>
            <thead className="bg-white">
              <tr>
                <th>Fecha</th>
                <th>Acción</th>
                <th>Usuario</th>
              </tr>
            </thead>
            <tbody>
              {rowData.rowData?.logs && rowData.rowData.logs.map((log, index) =>
                <tr key={index}>
                  <td>
                    <Moment date={log.createdDate} format="DD/MM/YYYY HH:mm" />
                  </td>
                  <td>
                    {log.tipoAccion}
                  </td>
                  <td>
                    {log.user.firstName + ' ' + log.user.lastName}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  </Container>
}



export const UserDP = ({ userId, listadoDeSitios, listadoDeUsuarios, listadoDeEquipos }) => {
  const [listaDeOrdenesDeTrabajo, setListaDeOrdenesDeTrabajo] = useState([]);
  const [id, setId] = useState(userId);
  useEffect(() => {
    ordenDeTrabajoService.traerListaDeOrdenesDeTrabajoPorUsuario(userId)
      .then(response => {
        setListaDeOrdenesDeTrabajo(response);
      })
      .catch(err => console.log(err))
    return () => {
      setListaDeOrdenesDeTrabajo([]);
    }
  }, [id])
  return (
    listaDeOrdenesDeTrabajo.map((ordenDeTrabajo, index) => {
      return <OrdenDeTrabajoDP key={index} rowData={{ rowData: ordenDeTrabajo }} listadoDeSitios={listadoDeSitios} listadoDeUsuarios={listadoDeUsuarios} listadoDeEquipos={listadoDeEquipos} />
    }))
}

function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

export const SitioDP = ({ rowData }) => {
  // Estado para controlar la visibilidad del modal
  const [showModal, setShowModal] = useState(false);
  rowData = rowData.rowData;
  // Asumiendo que rowData.imagen ya está en formato Base64.
  const base64Image = rowData ? rowData.imagen : null;

  // Funciones para abrir y cerrar el modal
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <Container fluid>
      <Row className="bg-silver p-3">
        <Col xs={2} className="mt-2">
          <h5><span className="badge badge-secondary font-weight-light">History</span></h5>
          {/* Visualización de la imagen */}
          {base64Image && (
            <img
              src={`data:image/png;base64,${base64Image}`}
              alt="Imagen del sitio"
              className="img-fluid"
              style={{ cursor: 'pointer' }}  // Cambia el cursor a una mano al pasar sobre la imagen
              onClick={handleShow}  // Abre el modal al hacer clic en la imagen
            />
          )}
        </Col>
        <Col xs={10} className="mt-2">
          <h5><span className="badge badge-secondary font-weight-light">Detalles del Sitio</span></h5>
          {/* Mostrar los datos del sitio */}
          <div>Nombre: {rowData ? rowData.nombre : 'Cargando...'}</div>
          <div>Cliente: {rowData ? rowData.cliente : 'Cargando...'}</div>
          <div>Fecha de inicio: {rowData ? rowData.fechaInicio : 'Cargando...'}</div>
        </Col>
      </Row>

      {/* Modal para mostrar la imagen en tamaño grande */}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Imagen del sitio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {base64Image && <img src={`data:image/png;base64,${base64Image}`} alt="Imagen del sitio" className="img-fluid" />}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export const OrdenDeTrabajoDP = ({ rowData, listadoDeSitios, listadoDeUsuarios, listadoDeEquipos, listadoDeCandados }) => {
  const [showModal, setShowModal] = useState(false);
  const [imageToShow, setImageToShow] = useState(null);

  const sitio = listadoDeSitios?.find(sitio => sitio.id === rowData.rowData.sitioId);

  console.log("RCC::OrdenDeTrabajoDP");
  // console.log("id: "+ listadoDeCandados[0].id);
  console.log("Cantidad de candados: "+ listadoDeCandados.length);
  // candado_sel = listadoDeCandados?.find(candado => candado.id === punto.candadoId);
 	
  const usuarios = listadoDeUsuarios?.map(usuario => {
    if (rowData.rowData.usuarios.includes(usuario.id)) {
      return usuario.firstName + ' ' + usuario.lastName;
    }
  }).filter(usuario => usuario !== undefined).join(', ');

  const equipos = listadoDeEquipos?.map(equipo => {
    if (rowData.rowData.equipos.includes(equipo.id)) {
      return `${equipo?.nombre}
      EPP: ${equipo?.epp ?? 'N/A'}
      Peligros: ${equipo.peligros?.map(peligro => peligro).join(', ')}`;
    }
  }).filter(equipo => equipo !== undefined).join(', ');
  const equiposAbloquear = listadoDeEquipos?.map(equipo => {
    if (rowData.rowData.equipos.includes(equipo.id)) {
      return equipo?.equiposAbloquear;
    }
  }).filter(equipo => equipo !== undefined).flat().flat();
  const equipoAsignado = listadoDeEquipos?.find(equipo => equipo?.id === rowData?.rowData?.equipos?.[0]);
  const [ubicacionCandados, setUbicacionCandados] = useState(rowData?.rowData?.candadosOrdenDeTrabajo);
  const [ ubicacionCandadosConImagenes, setUbicacionCandadosConImagenes ] = useState([]);
  const base64ImageSitio = sitio ? sitio.imagen : null;
  const handleShow = (image) => {
    setImageToShow(image);
    setShowModal(true);
  }
  const handleClose = () => setShowModal(false);

  const [imagenCandado, setImagenCandado] = useState(null);

  useEffect(() => {
    const cargarImagenCandado = async (id) => {
      try {
        const candadoPdT = await ordenDeTrabajoService.traerImagenCandado(id);
        setUbicacionCandadosConImagenes( prev => [...prev, candadoPdT]);
      } catch (error) {
        console.error(error);
      }
    };

    if (ubicacionCandados) {
      ubicacionCandados.forEach((candado, index) => {
        cargarImagenCandado(candado.id);
      });
    }
  }, []);
  return (
    <Container fluid>

	<Row className="bg-silver p-3">

        <Col xs={12}>
          <h5><span className="badge badge-secondary font-weight-light">Detalles de Permiso de Trabajo</span></h5>
          <Table className="sombra-1" hover>
            <tbody>
              <tr>
                <th>ID:</th>
                <td>{rowData.rowData.id}</td>
              </tr>
              <tr>
                <th>Nombre PdT:</th>
                <td>{rowData.rowData.nombre}</td>
              </tr>
              <tr>
                <th>Causa</th>
                <td>{rowData.rowData.causa}</td>
              </tr>
              <tr>
                <th>Descripción</th>
                <td>{rowData.rowData.descripcion}</td>
              </tr>
              <tr>
                <th>Empresa de mantenimiento</th>
                <td>{rowData.rowData.nombreEmpresaDeMantenimiento}</td>
              </tr>
              <tr>
                <th>Equipo Intervenido</th>
                <td>{equipoAsignado.nombre}</td>
              </tr>
              {equiposAbloquear?.map((equipo, equipoIndex) => (
                <tr key={`equipo-${equipoIndex}`}>
                  <th>Equipo a bloquear: <strong>{equipo.nombre}</strong></th>
                  <td>
                    <div>
                      {equipo?.puntosDeBloqueo?.map((punto, puntoIndex) => (
			<>
                        <div key={`punto-${equipoIndex}-${puntoIndex}`}>Punto de bloqueo: <strong>{punto.nombre}</strong> {punto.candadoId ? "- Candado asignado, Id: "+punto.candadoId :"Candado no asignado"} 
                        </div>
                        <span style={{ color: 'red', 'font-weight': 'bold' }}>{ punto.candadoId ? (listadoDeCandados?.find(candado => candado.id === punto.candadoId).locked ? "Bloqueo Activo":"") : "" }</span>
                        <span style={{ color: 'green', 'font-weight': 'bold' }}>{ punto.candadoId ? (listadoDeCandados?.find(candado => candado.id === punto.candadoId).locked ? "":"Candado Abierto") : "Abierto" }</span>
			</>
                      ))}

                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <th>Equipos</th>
                <td>{equipos}</td>
              </tr>
              <tr>
                <th>usuarios</th>
                <td>{usuarios}</td>
              </tr>
              <tr>
                <th>Fecha de Inicio</th>
                <td><Moment date={rowData.rowData.fechaInicio} format="DD/MM/YYYY HH:mm" /></td>
              </tr>
              <tr>
                <th>Fecha de Fin</th>
                <td>{rowData.rowData.fechaFin ? <Moment date={rowData.rowData.fechaFin} format="DD/MM/YYYY HH:mm" /> : 'N/A'}</td>
              </tr>
              <tr>
                <th>ubicacion de Candados</th>
                <td>
                  {ubicacionCandadosConImagenes &&
                    ubicacionCandadosConImagenes.map((candado, index) => {
                      return candado.imagenPokaYoke && (
                        <React.Fragment key={index}>
                          <img
                            src={`data:image/png;base64,${candado.imagenPokaYoke}`}
                            alt={`Candado ${index + 1}`}
                            className="img-fluid img-size"
                            style={{ marginRight: '10px', cursor: 'pointer', maxWidth: '200px' }}
                            onClick={() => handleShow(candado.imagenPokaYoke)}
                          />
                        </React.Fragment>
                      )
                    })}

                </td>
              </tr>
              <tr>
                <th>Riesgos</th>
                <td>{rowData.rowData.riesgos}</td>
              </tr>
              <tr>
                <th>Síntoma</th>
                <td>{rowData.rowData.sintoma}</td>
              </tr>
              <tr>
                <th>Sitio</th>
                <td>{sitio?.nombre}</td>
              </tr>
              <tr>
                <th>Imagen del Sitio</th>
                <td>
                  {base64ImageSitio && (
                    <img
                      src={`data:image/png;base64,${base64ImageSitio}`}
                      alt="Imagen del sitio"
                      className="img-fluid img-size"
                      style={{ cursor: 'pointer', maxWidth: '200px' }}
                      onClick={() => handleShow(base64ImageSitio)}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Detalle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {imageToShow && <img src={`data:image/png;base64,${imageToShow}`} alt="Imagen" className="img-fluid" />}
        </Modal.Body>
      </Modal>
    </Container>
  )
}


export const EquipoDp = ({ rowData, listadoDeEquipos, listadoDeCandados }) => {
	
  const equipo = rowData.rowData;
  equipo.equiposAbloquear = listadoDeEquipos?.filter(e => e.id === equipo.id)[0]?.equiposAbloquear;

  return (
    <Container fluid>

      <Row className="bg-silver p-3">
        <Col xs={12}>
          <h5><span className="badge badge-secondary font-weight-light">Detalles del Equipo</span></h5>
          <Table className="sombra-1" hover>
            <tbody>
              <tr>
                <th>Nombre:</th>
                <td>{equipo.nombre}</td>
              </tr>
              <tr>
                <th>Peligros:</th>
                <td>{equipo?.peligros?.join(" ,")}</td>
              </tr>
              <tr>
                <th>Equipo de proteccion personal:</th>
                <td>{equipo.epp}</td>
              </tr>
              <tr>
                <th>Sitio:</th>
                <td>{equipo?.sitio?.nombre}</td>
              </tr>
              {equipo?.equiposAbloquear?.map((equipo, equipoIndex) => (
                <tr key={`equipo-${equipoIndex}`}>
                  <th>Equipo a bloquear: <strong>{equipo.nombre}</strong></th>
                  <td>
                    <div>

                      {equipo?.puntosDeBloqueo?.map((punto, puntoIndex) => (
                        <>
                        <div key={`punto-${equipoIndex}-${puntoIndex}`}>Punto de bloqueo: <strong>{punto.nombre}</strong> {punto.candadoId ? " - Candado asignado, Id: "+punto.candadoId : "Candado no asignado"}
			</div>
                        <span style={{ color: 'red', 'font-weight': 'bold' }}>{ punto.candadoId ? (listadoDeCandados?.find(candado => candado.id === punto.candadoId).locked ? "Bloqueo Activo":"") : "" }</span>
                        <span style={{ color: 'green', 'font-weight': 'bold' }}>{ punto.candadoId ? (listadoDeCandados?.find(candado => candado.id === punto.candadoId).locked ? "":"Candado Abierto") : "Abierto" }</span>
                        </>

                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );

}


export const prepareDataSearch = (query, initialOrder, searchValues) => {
  let dataSearch = {
    searchValue: query.search,
    page: query.page,
    pageSize: query.pageSize,
    sortOrders: [
      {
        field: query.orderBy ? query.orderBy.field : initialOrder,
        direction: query.orderDirection ? query.orderDirection.toUpperCase() : 'ASC'
      }
    ],
    options: prepareValuesToSearch(searchValues)
  };

  return dataSearch;
}


export const prepareValuesToSearch = (values) => {
  let finalValues = {};

  if (values) {
    Object.keys(values).forEach(function (key) {
      const value = values[key];
      finalValues[key] = (value.hasOwnProperty('id') ? value.id : value);
    });
  }

  return finalValues;
}

export const hasValue = (val) => {
  return val !== undefined && val != null && val !== '';
}

export const LoadingBoton = ({ grabando }) => (

  <div className="sweet-loading">
    <BeatLoader
      size={10}
      color={"#FFFFFF"}
      loading={grabando}
    />
  </div>
);

export const exportToCSV = (csvData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export const exportArrayToCSV = (arrayData, fileName) => {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const wb = { Sheets: {}, SheetNames: ['data'] };

  arrayData.forEach((element, index) => {
    const ws = XLSX.utils.json_to_sheet(element.data);
    wb.Sheets[element.name] = ws;
    wb.SheetNames[index] = element.name;
  });

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}



export const ExportCSV = ({ csvData, fileName }) => {

  return (
    <button className="btns btns-outline-recursos  btn-block" onClick={(e) => exportToCSV(csvData, fileName)}><i className="fas fa-download"></i> Exportar excel</button>
  )
}

const getImage = (id) => {
  ordenDeTrabajoService.traerImagenCandado(id).then(response => {
    return response;
  }).catch(err => console.log(err));
}


